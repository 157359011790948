import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalErrorCode } from 'src/selectors/global-error-selector';

import { setGlobalError } from '@actions/global-error';
import { GlobalErrorBlock, SiteFooter, SiteHeader, SiteHeaderMinimal } from '@components';

const SiteLayout = ({ children, isMinimalHeader }) => {
    const globalErrorCode = useSelector(getGlobalErrorCode);
    const dispatch = useDispatch();

    const router = useRouter();

    useEffect(() => {
        const handleRouteChange = () => {
            dispatch(setGlobalError(null));
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [dispatch, router.events]);

    return (
        <>
            <Head>
                <meta name='viewport' content='width=device-width, minimum-scale=1, maximum-scale=5, initial-scale=1' />
            </Head>
            {isMinimalHeader ? <SiteHeaderMinimal /> : <SiteHeader />}
            <main>{globalErrorCode ? <GlobalErrorBlock /> : children}</main>
            <SiteFooter />
        </>
    );
};

export default SiteLayout;
