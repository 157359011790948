import React from 'react';
import { useSelector } from 'react-redux';
import { getPersonalSelector } from 'src/selectors/personal-selector';

import { Button } from '@components';
import { verificationStatuses } from '@constants';
import { ClockIcon } from '@images/16-clock.svg';
import { CheckIcon } from '@images/check.svg';

import './specialist-verification.scss';

const SpecialistVerification = () => {
    const { basicData } = useSelector(getPersonalSelector);

    return (
        <div className='specialist-verification'>
            {basicData.verification_data.status === verificationStatuses.new && <a className='specialist-verification_new' href='/personal/my-profile/verify'>
                Подтвердить профиль
            </a>}
            {basicData.verification_data.status === verificationStatuses.under_review && <div className='specialist-verification__inner'>
                <p className='specialist-verification_under_review'><ClockIcon />На проверке</p>
                <a href='/personal/my-profile/verify'>
                    <Button size='small' view='secondary-outline'>
                        Посмотреть детали
                    </Button>
                </a>
            </div>}
            {basicData.verification_data.status === verificationStatuses.not_verified && <div className='specialist-verification__inner'>
                <p className='specialist-verification_not_verified'>Профиль не подтвержден</p>
                <a href='/personal/my-profile/verify' >
                    <Button size='small' view='secondary-outline' className='specialist-verification__btn'>
                        Подтвердить
                    </Button>
                </a>
            </div>}
            {basicData.verification_data.status === verificationStatuses.verified && <p className='specialist-verification_verified'>
                <CheckIcon />Профиль подтвержден
            </p>}
        </div>
    );
};

export default SpecialistVerification;