import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGlobalErrorCode } from 'src/selectors/global-error-selector';

import { setGlobalError } from '@actions/global-error';
import { GlobalErrorBlock, SiteFooter, SiteHeader, SiteHeaderMinimal } from '@components';
import { useAdaptive } from '@hooks';

import PersonalTabs from '../../pages/site/personal-page/PersonalTabs/PersonalTabs';

import { DesktopSidebar } from './components/desktop-sidebar';

import './personal-account-layout.scss';

const PersonalAccountLayout = ({ children, isMinimalHeader }) => {
    const globalErrorCode = useSelector(getGlobalErrorCode);
    const dispatch = useDispatch();

    const router = useRouter();

    const { isMobile, isSmallMobile } = useAdaptive();

    useEffect(() => {
        const handleRouteChange = () => {
            dispatch(setGlobalError(null));
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [dispatch, router.events]);

    return (
        <>
            <Head>
                <meta name='viewport' content='width=device-width, minimum-scale=1, maximum-scale=5, initial-scale=1' />
            </Head>
            {isMinimalHeader ? <SiteHeaderMinimal /> : <SiteHeader />}
            <main>
                {globalErrorCode ?
                    <GlobalErrorBlock />
                    :
                    <>
                        {!isMobile && !isSmallMobile &&
                            <div className='container'>
                                <div className='way'>
                                    <Link href={{ pathname: '/site/main-page' }} as='/'>
                                        Главная
                                    </Link>
                                    <span>Личный кабинет</span>
                                </div>
                            </div>
                        }
                        <div className='container'>
                            <div className='personal-account-layout'>
                                <DesktopSidebar />
                                {children}
                            </div>
                        </div>
                    </>
                }
            </main>
            {isMobile || isSmallMobile ? <PersonalTabs tabsAmount={isSmallMobile ? 3 : 6} /> : <SiteFooter />}
        </>
    );
};

export default PersonalAccountLayout;
