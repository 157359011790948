import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';

import { Logo } from '@components';

import './sidebar.scss';

const routes = [
    {
        name: 'Пользователи',
        href: '/new-admin/users-page',
        as: '/admin/users',
    },
    {
        name: 'Справочники',
        href: '/new-admin/handbooks-page',
        as: '/admin/handbooks',
    },
    {
        name: 'Статьи',
        href: '/new-admin/articles-page',
        as: '/admin/articles',
    },
    {
        name: 'Категории статей',
        href: '/new-admin/articles-categories-page',
        as: '/admin/articles-categories',
    },
    {
        name: 'Хэштеги статей',
        href: '/new-admin/articles-hashtags-page',
        as: '/admin/articles-hashtags',
    },
    {
        name: 'Категории специалистов',
        href: '/new-admin/specialists-categories-page',
        as: '/admin/specialists-categories',
    },
    {
        name: 'Адвокатское образование',
        href: '/new-admin/law-educations-page',
        as: '/admin/law-educations',
    },
    {
        name: 'Типы пользователей',
        href: '/new-admin/users-types-page',
        as: '/admin/users-types',
    },
    {
        name: 'Верификация',
        href: '/new-admin/verification',
        as: '/admin/verification',
    },
];

const AdminSidebar = () => {
    const { asPath } = useRouter();

    return <div className='admin-sidebar'>
        <Link href='/site/main-page' as='/'>
            <a><Logo /></a>
        </Link>
        <div className='admin-sidebar__menu'>
            {routes.map(({ name, href, as }) =>
                as === asPath ?
                    <div key={href} className='admin-sidebar__menu-item admin-sidebar__menu-item_active'>
                        {name}
                    </div> :
                    <Link key={href} href={href} as={as}>
                        <a className='admin-sidebar__menu-item'>{name}</a>
                    </Link>,
            )}
        </div>
    </div>;
};

export default AdminSidebar;
