import Head from 'next/head';
import React from 'react';

import Sidebar from './sidebar';

import './admin-layout.scss';

const AdminLayout = ({ children }) => {
    return (
        <>
            <Head>
                <meta name='viewport' content='width=device-width, minimum-scale=1, maximum-scale=5, initial-scale=1' />
            </Head>
            <main className='admin-layout'>
                <Sidebar />
                <div className='admin-layout__content'>{children}</div>
            </main>
        </>
    );
};

export default AdminLayout;
