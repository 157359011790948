import classNames from 'classnames';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPersonalSelector } from 'src/selectors/personal-selector';

import { useModal } from '@hooks';
import CloseIcon from '@images/closePopup.svg';
import CaseIcon from '@images/data.svg';
import DocIcon from '@images/menu-icon-doc.svg';
import MoreIcon from '@images/more-vert.svg';
import NotificationIcon from '@images/notification.svg';
import OrdersIcon from '@images/orders.svg';
import PersonalIcon from '@images/personal.svg';
import ProfileIcon from '@images/profile.svg';

import PersonalPopup from './PersonalPopup/PersonalPopup';

import './PersonalTabs.scss';

const PersonalTabs = ({ tabsAmount }) => {
    const router = useRouter();

    const [isOpen, openModal, closeModal] = useModal();

    const { counters } = useSelector(getPersonalSelector);

    const tabs = useMemo(() => {
        return [
            {
                key: 'workspace',
                label: 'Рабочий стол',
                counts: {},
                content: <></>,
                icon: <PersonalIcon />,
                paintType: 'fill',
            },
            {
                key: 'documents',
                label: 'Документы',
                counts: {
                    total: counters?.documents?.count,
                    new: counters?.documents?.count_unview,
                },
                content: <></>,
                icon: <DocIcon />,
                paintType: 'stroke',
            },
            {
                key: 'my-cases',
                label: 'Мои дела',
                counts: {
                    total: counters?.legal_cases?.count,
                    new: counters?.legal_cases?.count_unview,
                },
                content: <></>,
                icon: <CaseIcon />,
                paintType: 'fill',
            },
            {
                key: 'orders',
                label: 'Заявки',
                counts: {
                    total: counters?.orders?.count,
                    new: counters?.orders?.count_unview,
                },
                content: <></>,
                icon: <OrdersIcon />,
                paintType: 'stroke',
            },
            {
                key: 'my-profile',
                label: 'Профиль',
                counts: {},
                content: <></>,
                icon: <ProfileIcon />,
                paintType: 'stroke',
            },
            {
                key: 'notification',
                label: 'Уведомления',
                counts: {},
                content: <></>,
                icon: <NotificationIcon />,
                paintType: 'stroke',
            },
        ];
    }, [counters]);

    const [currentTab, setCurrentTab] = useState('');

    useEffect(() => {
        setCurrentTab(router.asPath);
    }, [router.asPath]);

    const onChangeTabHandler = useCallback(
        tabKey => {
            router.push(
                {
                    pathname: `/site/personal-page/${tabKey}`,
                },
                `/personal/${tabKey}`,
                { shallow: true },
            );
        },
        [router],
    );

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        }

        return () => document.body.style.overflow = 'visible';
    }, [isOpen]);

    return (
        <div className='personal-tabs'>
            <PersonalPopup handleClose={closeModal} isOpen={isOpen} tabs={tabs} />
            <div className='personal-tabs__row'>
                {tabs.slice(0, tabsAmount).map(item =>
                    <button
                        key={item.key}
                        className={classNames('personal-tabs__icon', {
                            'personal-tabs__icon_active': `/personal/${item.key}` === currentTab,
                            'personal-tabs__icon_stroke-active': `/personal/${item.key}` === currentTab && item.paintType === 'stroke',
                            'personal-tabs__icon_fill-active': `/personal/${item.key}` === currentTab && item.paintType === 'fill',
                        })}
                        type='button'
                        onClick={() => onChangeTabHandler(item.key)}
                    >
                        {item.icon}
                        <span>{item.label}</span>
                        <div className='personal-tabs__icon-badge'>
                            {item.counts.total !== undefined && <span className='personal-tabs__icon-badge-total'>{item.counts.total}</span>}
                            {item.counts.new !== undefined && item.counts.new !== 0 &&
                                <span className='personal-tabs__icon-badge-new'>+{item.counts.new}</span>
                            }
                        </div>
                    </button>,
                )}

                <button className='personal-tabs__control' type='button'>
                    {isOpen ? <CloseIcon onClick={closeModal} /> : <MoreIcon onClick={openModal} />}
                </button>
            </div>
        </div>
    );
};

export default PersonalTabs;
