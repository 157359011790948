import Head from 'next/head';
import React from 'react';

const EmptyLayout = ({ children }) => {
    return (
        <React.Fragment>
            <Head>
                <meta name='viewport' content='width=device-width, minimum-scale=1, maximum-scale=5, initial-scale=1' />
            </Head>
            <main>{children}</main>
        </React.Fragment>
    );
};

export default EmptyLayout;
