import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import SpecialistVerification from 'src/layouts/personal-account-layout/components/desktop-sidebar/SpecialistVerification/SpecialistVerification';
import Slide from '@material-ui/core/Slide';

import { isDecimal, pluralizeReviews, userRole } from '@constants';
import CheckIcon from '@images/check.svg';

import './PersonalPopup.scss';

function PersonalPopup({ isOpen, handleClose, tabs, personal: { basicData } }) {
    const { asPath } = useRouter();

    const rating = useMemo(() => isDecimal(basicData?.specialist_data?.rating)
        ? basicData.specialist_data?.rating?.toFixed(1)
        : basicData.specialist_data?.rating, [basicData?.specialist_data?.rating]);

    return (
        <Slide direction='up' in={isOpen} mountOnEnter={true} unmountOnExit={true}>
            <div className='popup'>
                <div className='popup__overlay' onClick={handleClose}></div>
                <div className='popup__main'>
                    <div className='popup__header'>
                        <div className='popup__header-row'>
                            <h5 className='popup__header-username'>
                                {`${basicData.first_name} ${basicData.middle_name ?? ''} ${basicData.last_name}`}
                            </h5>
                            <span className='popup__header-check'>
                                <CheckIcon />
                            </span>
                        </div>
                        {basicData.type_key !== userRole.client &&
                            <>
                                <div className='popup__header-row'>
                                    <p className='popup__user-role'>{basicData.specialist_data?.category_specialist?.label}</p>
                                    <span className='popup__rating-dot'></span>
                                    <span className='popup__rating'>{rating}</span>
                                    <span className='popup__rating-dot'></span>
                                    {basicData?.specialist_data?.review_count && <p className='popup__reviews'>
                                        {pluralizeReviews(basicData.specialist_data.review_count)}
                                    </p>}
                                </div>
                                <SpecialistVerification />
                            </>
                        }
                    </div>
                    <div className='popup__menu'>
                        {tabs.map(({ key, label }) =>
                            `/personal/${key}` === asPath ?
                                <div key={`/site/personal-page/${key}`} className='popup__menu-item popup__menu-item_active'>
                                    {label}
                                </div>
                                :
                                <Link key={`/site/personal-page/${key}`} href={`/site/personal-page/${key}`} as={`/personal/${key}`} >
                                    <a className='popup__menu-item' onClick={handleClose}>{label}</a>
                                </Link>
                            ,
                        )}
                    </div>
                </div>
            </div>
        </Slide >
    );
}

export default connect(state => ({
    authUserToken: state.currentUser.authUserToken,
    personal: state.personal,
}))(PersonalPopup);
